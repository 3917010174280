import React, { FunctionComponent, useEffect } from "react";
import CSS from "csstype";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { useGlobalState } from "../VolumeContext";
library.add(faVolumeMute, faVolumeUp, faFacebookSquare);

export type CardProps = {
  src: string;
  caption?: string;
  isWide: boolean;
  innerClassName?: string;
  innerStyle?: CSS.Properties;
  innerInnerStyle?: CSS.Properties;
  muted?: boolean;
  volume?: number;
  outerClass?: string;
  link?: string;
  download?: string;
  download2?: string;
};

export const ImageIcon: FunctionComponent<CardProps> = (props) => {
  //  function ImageIcon(prop: {src: string, caption: string, isWide: boolean, innerClassName: string }): JSX.Element {
  var img: JSX.Element = (
    <div
      className={props.outerClass + " imgContainer"}
      style={props.innerStyle}
    >
      { 
      props.link&&  <a style={{}} className="bottom-left" href={props.link}>{props.caption}</a>
      }
      { 
      !props.link&&  <div style={{}} className="bottom-left"  >{props.caption}</div>
      }

      { 
      props.download&&  <a style={{}} className="top-left" href={props.download}>Download</a>
      }  { 
      props.download2&&  <a style={{}} className="top-left2" href={props.download2}>Download 2</a>
      }
      <img
        src={props.src}
        alt="logo"
        style={props.innerInnerStyle}
        className={
          (props.isWide === true ? "wideArt" : "") + " " + props.innerClassName
        }
      ></img>
      {props.children}
    </div>
  );
  return img;
};

export const TextIcon: FunctionComponent<CardProps> = (props) => {
  //  function ImageIcon(prop: {src: string, caption: string, isWide: boolean, innerClassName: string }): JSX.Element {
  var img: JSX.Element = (
    <div className="imgContainer  ">
      <table
        style={{
          height: "250px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                display: "table-cell",
                verticalAlign: "center",
                textAlign: "center",
              }}
            >
              <h2>{props.caption}</h2>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
  //    <img src={props.src} alt="logo" style={props.innerInnerStyle} className={((props.isWide === true) ? "wideArt" : "") + " " + props.innerClassName}></img>

  return img;
};

export const TextSnippetIcon: FunctionComponent<{
  textSnippet: TextSnippet;
  topHeightPercent: number;
}> = (props) => {
  //  function ImageIcon(prop: {src: string, caption: string, isWide: boolean, innerClassName: string }): JSX.Element {
  var img: JSX.Element = (
    <div className="imgContainer  ">
      <p
        style={{
          position: "absolute",
          top: "50%",
          transform: "translate(0, -50%)",
          paddingLeft: "1em",
          paddingRight: "1em",
          fontSize: "larger",
          fontVariant: "smallCaps",
          paddingTop: ".5em",
          paddingBottom: ".5em",
        }}
      >
        {props.textSnippet.header}
      </p>
    </div>
  );
  //    <img src={props.src} alt="logo" style={props.innerInnerStyle} className={((props.isWide === true) ? "wideArt" : "") + " " + props.innerClassName}></img>

  return img;
};

export const VideoBackground: FunctionComponent<{
  src: string;
  innerStyle?: CSS.Properties;
  innerClassName: string;
}> = (props) => {
  var videoElement: JSX.Element = (
    <video
      autoPlay
      muted
      controls={false}
      playsInline={true}
      loop={true}
      style={props.innerStyle}
      src={props.src}
      className={props.innerClassName}
    >
      {" "}
      {props.children}{" "}
    </video>
  );
  return videoElement;
};

export const VideoIcon: FunctionComponent<CardProps> = (props) => {
  var img: JSX.Element = (
    <div
      className={props.outerClass + " imgContainer VideoIcon"}
      style={props.innerStyle}
    >
      {props.caption && <div className="bottom-left">{props.caption}</div>}
      <video
        autoPlay
        muted
        loop={true}
        playsInline={true}
        controls={false}
        style={props.innerInnerStyle}
        className={
          (props.isWide === true ? "wideArt" : "") + " " + props.innerClassName
        }
      >
        <source src={props.src} type="video/mp4" />
      </video>
      {props.children}{" "}
    </div>
  );
  return img;
};

export const VideoIconWithMusic: FunctionComponent<CardProps> = (props) => {
  const { state, setState } = useGlobalState();

  useEffect(() => {
    if (!props.muted) {
      setState((prev) => ({
        ...prev,
        numberOfSourcesInForground: (prev.numberOfSourcesInForground || 0) + 1,
      }));
      return () => {
        setState((prev) => ({
          ...prev,
          numberOfSourcesInForground:
            (prev.numberOfSourcesInForground || 0) - 1,
        }));
      };
    }
  }, [props, state.muted, setState]);

  return (
    <div>
      <video
        autoPlay={true}
        loop={true}
        playsInline={true}
        controls={false}
        muted={props.muted || state.muted}
        src={props.src}
        onLoadStart={(event: React.SyntheticEvent<HTMLVideoElement>) => {
          if (props.volume) {
            //                    if (props.muted || state.muted) {
            event.currentTarget.volume = props.volume;
            //                  }
          }
        }}
        onClick={() => {}}
        style={props.innerInnerStyle}
        className={
          (props.isWide === true ? "wideArt" : "") + " " + props.innerClassName
        }
      />
      <div className="">{props.caption}</div>
      {props.children}
    </div>
  );
  //   muted={volume.muted}
};

export type PageOneIcon = {
  thumbnail: JSX.Element;
  buttonText: string;
};

export type TextSnippet = {
  header: string;
  lineItem: string;
};

export class IntroText extends React.Component<{
  snippets: TextSnippet[];
  delaySeconds: number;
  topHeightPercent: number;
}> {
  state = { index: 0 };
  timeout: NodeJS.Timeout | null = null;

  componentDidMount() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = null;
    this.timeout = setTimeout(() => {
      this.setState({ index: this.state.index + 1 });
    }, this.props.delaySeconds * 1000);
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = null;
  }

  render() {
    return (
      <TextSnippetIcon
        topHeightPercent={this.props.topHeightPercent}
        textSnippet={
          this.props.snippets[this.state.index % this.props.snippets.length]
        }
      ></TextSnippetIcon>
    );
  }
}



export class EYEFrame extends React.Component<{
  src: string;
}> {
  render() {
    return (
      <div>
      <iframe 
      title="preview"
      style={{
        position:"absolute",
        left: "0px",
        top:"0px",
        width: "100vw",
    //    padding: "1em",
        height: "100vh",
     //   borderRadius: "4em",
        borderStyle: "none"
      }} src={this.props.src}></iframe>


<a
href="/"
                 style={{
                  position:"absolute",
                  left: "40vw",
                  width:"10vw",
                  margin: ".25em",
                  top:"-.5em",
                  borderBottomLeftRadius: "1em",
                  borderBottomRightRadius: "1em",
                  borderStyle: "solid",
                  backgroundColor:"white"
                }}
                
                >Home</a>
                </div>

    );
  }
}


