import React, { Fragment, FunctionComponent } from "react";
import CSS from "csstype";
import "./App.css";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { allIcons } from "./components/Snipets";
import {
  EYEFrame,
} from "./components/Icons";


import { GlobalStateProvider } from "./VolumeContext";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Link,
} from "react-router-dom";

import { urlhost } from "./env";

const Centered: FunctionComponent = (props) => (
  <div className="centerText">{props.children}</div>
);
const AnimatePulse: FunctionComponent = (props) => {
  return (
    <div className="animate__animated animate__pulse">{props.children}</div>
  );
};

function About() {
  return (
    <AnimatePulse>
      <div className="pillBox title " style={{ textAlign: "left" }}>
        <h1>Hunger Rush Prototypes</h1>
        <div>
          <Centered>
            Game Portfolio by Dr.&nbsp;Clement&nbsp;Shimizu
            for Mighty &amp; True<br />
          </Centered>
          <Centered>
          <img
            alt="Creator Dr. Clement Shimizu"
            style={{
              width: "128px",
              height: "128px",
            }}
            src={`${urlhost}/credits/Dr-Clement-Shimizu.png`}
          ></img>
      </Centered>
      </div>
      </div>
    </AnimatePulse>
  );
}


function Screenshots() {
  return (
    <div style={{
      maxWidth: "1000px",
      marginLeft: "auto",
      marginRight: "auto"
    }}>

            <MyNavBar />
      {allIcons.map((CC, b) => (
        <Fragment key={b}>{CC}</Fragment>
      ))}

            <MyFooter />
    </div>
  );
}

function MyNavBar() {
  var style: CSS.Properties = {}; // borderStyle: "solid", borderWidth: "1px", borderColor: "black" };
  var style2: CSS.Properties = {
    backgroundColor: "white",
    borderColor: "black",
    borderStyle: "solid",
    color: "black",
    fontSize: "x-large",
    cursor: "default",
    borderRadius: "0.5em",
    marginTop:".25em",
    paddingTop: "0px",
    marginLeft: "auto",
    marginRight:"auto"
  };  
  return (
    <Navbar style={{
    marginTop:"0px",
    paddingTop:"0px"

    }}>
      <Nav className="  " style={{ marginLeft: "auto", marginRight: "auto",marginTop:"0px",
    paddingTop:"0px"}}>
        <ButtonGroup>
       
          <Button style={style2} as={Link as any} to="/intro">
           Hunger Rush Game Prototypes
          </Button>
      
        </ButtonGroup>
      </Nav>
    </Navbar>
  );
}
function MyFooter() {
  return (
    <div className="TopLevel" style={{
      backgroundColor: "white",
      borderColor: "black",
      borderStyle: "solid",
      color: "black",
      borderRadius: "0.5em",
      marginTop: "0px",
      padding:"0px",
      marginLeft: "auto",
      marginRight: "auto"
    }}>
      <Link style={{}} to="/contact">
      @DrClementShimizu / Mighty &amp; True for Hunger Rush &copy; 2022
      </Link>
    </div>
  );
}

function Debug() {
  return <div></div>;
}
class App extends React.Component {
  render() {
    return (
      <GlobalStateProvider>
        <div
          className="App TopLevelContainer AppMargin"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <Router>
            <Routes>
            <Route path="/games/GUITest/" element={<EYEFrame src="/games/ButtonTestNotCompressed/embed.html" />}></Route>
              <Route path="/look" element={<Screenshots/>} />
              <Route path="/contact" element={<About/>} />
              <Route path="/games/Subway/" element={
               

<EYEFrame src="/games/SubwayNotCompressed/embed.html" />

 
               }></Route>
   <Route path="/games/ResturantV1/" element={<EYEFrame src="/games/Resturant/embed.html" />}></Route>
           


              <Route path="/games/DriverDemo/" element={<EYEFrame src="/games/city/embed.html" />}></Route>
              <Route path="/games/ResturantV2/" element={<EYEFrame src="/games/Rest2/embed.html" />}></Route>
              <Route path="/games/HungerRushCleanupV1" element={<EYEFrame src="/games/HungerRushCleanup/embed.html" />}></Route>
              <Route path="/games/OrderOverloadV1/" element={<EYEFrame src="/games/OrderOverload/embed.html" />}></Route>
              <Route path="*" element={<Screenshots/>} />
              </Routes>
          </Router>
        </div>
      </GlobalStateProvider>
    );
  }
}
export default App;
