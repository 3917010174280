var BUILD_ENVstr: string = process.env.REACT_APP_BUILD_ENV || "local";

var isDev = BUILD_ENVstr === "awsdev";
var islocal = BUILD_ENVstr === "local";

export const BUILD_ENV = islocal ? "local" : isDev ? "awsdev" : "awsprod";

export const urlhost = islocal
  ? ""
  : isDev
  ? "https://cutiebops-web-dev.s3.amazonaws.com"
  : "https://cutiebops-web-prod.s3.amazonaws.com";

export const urlhostweb = islocal
  ? "http://localhost:3000"
  : isDev
  ? "https://dev.cutiebops.com"
  : "https://prod.cutiebops.com";

export const urlhostweb_ENCODED_NO_LOCAL = islocal
  ? "http%3A%2F%2Fdev.cutiebops.com"
  : isDev
  ? "http%3A%2F%2Fdev.cutiebops.com"
  : "http%3A%2F%2Fprod.cutiebops.com";

// this will return the directories for local and online
