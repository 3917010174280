import {
  ImageIcon,
} from "./Icons";
import { urlhost } from "../env";
var keyIndex: number = 2000;


export const allIcons: JSX.Element[] = [

  <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/subway.png`}
    caption="first prototype"
    isWide={false}
    link="/games/Subway"
  >
  </ImageIcon>,


 <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/gui.png`}
    caption="3D GUI"
    isWide={false}
    link="/games/GUITest"
  >
  </ImageIcon>,

  
 <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/resturantold.png`}
    caption="Resturant V 1"
    isWide={false}
    link="/games/ResturantV1"
  >
  </ImageIcon>,
  <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/pizzaParty.png`}
    caption="Hunger Rush Cleanup"
    isWide={false}
    link="/games/HungerRushCleanupV1"
  >
  </ImageIcon>,

  

 <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/resturantnew.png`}
    caption="New Resturant"
    isWide={false}
    link="/games/ResturantV2"
  >
  </ImageIcon>,

 <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/uber2.png`}
    caption="Driver Demo"
    isWide={false}
    link="/games/DriverDemo/"
  >
  </ImageIcon>,
  
  
  
  
 <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/OrderOverload.png`}
    caption="Order Overload"
    isWide={false}
    link="/games/OrderOverloadV1/"
  >
  </ImageIcon>,
  
    <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/CouponRushIcon.png`}
    caption="Coupon Rush Concept"
    isWide={false}
    link="/games/CouponRush/CouponRush.png"
    download="/games/CouponRush/CouponRush.psd"
    download2="/games/CouponRush/CouponRush2.psd"
  >
  </ImageIcon>,
 <ImageIcon
    key={keyIndex++}
    innerClassName=""
    src={`${urlhost}/gifs/ransom.png`}
    caption="Coupon Rush Concept"
    isWide={false}
    link="/games/HungerRushRansom/HungerRushRansom.png"
    download="/games/HungerRushRansom/HungerRushRansom.psd"
  >
  </ImageIcon>
  
];
